import { storeToRefs } from 'pinia'
import type { Asset, Media, Repo, Skill, SkillsOnRepos, TopicsOnRepos } from '@prisma/client'
import type {
  LocalBusiness,
  Organization,
  Person,
  Place,
  PostalAddress,
  QuantitativeValue,
  WebPage,
  WebSite,
  WithContext
} from 'schema-dts'
import { useRadar } from '~/store/radar'
import { useLocaleSanitizer } from '~/composables/locale'
import type { CountryCode } from '~/types/custom'

export const useStructuredData = (page: string) => {
  const { routeRaw } = storeToRefs(useRadar())
  const { t, locale } = useI18n()
  const localePath = useLocalePath()
  const path =
    typeof routeRaw.value?.fullPath === 'string' && routeRaw.value.fullPath.includes('?')
      ? routeRaw.value?.fullPath?.substring?.(0, routeRaw.value?.fullPath?.indexOf?.('?'))
      : routeRaw.value?.fullPath

  const rottigniTechOrganization: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': `https://rottigni.tech${localePath('/')}#Organization`,
    url: `https://rottigni.tech${localePath('/')}`,
    name: 'LR Labs',
    logo: 'https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/logo/LR_labs/LR_labs_logo.png'
  }
  const lorenzoRottigniOrganization: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': `https://portfolio.rottigni.tech${localePath('/')}#Organization`,
    url: `https://portfolio.rottigni.tech${localePath('/')}`,
    name: 'Lorenzo Rottigni',
    logo: 'https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/logo/LR_labs/LR_labs_logo.png'
  }
  const deesupOrganization: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: locale.value === 'it' ? 'https://www.deesup.com' : 'https://www.deesup.com/en',
    '@id': locale.value === 'it' ? 'https://www.deesup.com/#Organization' : 'https://www.deesup.com/en#Organization',
    name: 'Deesup'
  }

  return useJsonld(() => [
    {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name: 'Lorenzo Rottigni',
      description:
        'Freelance software developer and consultant. I specialize in web development, mobile development, and data science..',
      telephone: '+39 3806947004',
      url: 'https://portfolio.rottigni.tech/',
      currenciesAccepted: 'EUR',
      paymentAccepted: 'Cash, Credit Card',
      openingHours: ['Mo-Fr 9:00-18:00'],
      priceRange: '$$',
      image: 'https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/logo/LR_labs/LR_labs_logo.png',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Milan',
        addressCountry: 'Italy',
        streetAddress: 'Via turati 12'
      } as WithContext<PostalAddress>
    } as WithContext<LocalBusiness>,
    {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name: 'LR Labs',
      description:
        'Freelance software developer and consultant. I specialize in web development, mobile development, and data science..',
      telephone: '+39 3806947004',
      url: 'https://rottigni.tech/',
      currenciesAccepted: 'EUR',
      paymentAccepted: 'Cash, Credit Card',
      openingHours: ['Mo-Fr 9:00-18:00'],
      priceRange: '$$',
      image: 'https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/logo/LR_labs/LR_labs_logo.png',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Milan',
        addressCountry: 'Italy',
        streetAddress: 'Via turati 12'
      } as WithContext<PostalAddress>
    } as WithContext<LocalBusiness>,
    {
      '@context': 'https://schema.org',
      '@type': 'Person',
      '@id': `https://portfolio.rottigni.tech${localePath('/')}#Person`,
      url: `https://portfolio.rottigni.tech/${localePath('/')}`,
      name: 'Lorenzo Rottigni',
      address: 'Burago di Molgora (MB), Italy',
      birthDate: '2000-12-24',
      jobTitle: 'Full-Stack Web Developer',
      birthPlace: {
        '@type': 'Place',
        address: 'Monza Brianza(MB), Italy'
      } as WithContext<Place>,
      email: 'lorenzo@rottigni.net',
      familyName: 'Rottigni',
      gender: 'Male',
      telephone: '+39 3806947004',
      weight: {
        '@type': 'QuantitativeValue',
        unitCode: 'KGM',
        value: 80
      } as WithContext<QuantitativeValue>,
      height: {
        '@type': 'QuantitativeValue',
        unitCode: 'CMT',
        value: 185
      } as WithContext<QuantitativeValue>,
      image: 'https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/md.webp',
      sameAs: [
        'https://www.linkedin.com/in/lorenzo-rottigni-ba402b222/',
        'https://www.instagram.com/lorenzorottigni/',
        'https://gitlab.com/LorenzoRottigni/',
        'https://github.com/LorenzoRottigni/'
      ],
      worksFor: [deesupOrganization, rottigniTechOrganization],
      affiliation: [deesupOrganization, rottigniTechOrganization]
    } as WithContext<Person>,
    {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      '@id': path ? `https://portfolio.rottigni.tech${path}#WebPage` : undefined,
      name: t(`routes.${page}.title`),
      description: t(`routes.${page}.description`),
      url: `https://portfolio.rottigni.tech${path}`,
      inLanguage: useLocaleSanitizer(locale.value as CountryCode) || undefined,
      isPartOf: [
        {
          '@type': 'WebSite',
          '@id': `https://portfolio.rottigni.tech${localePath('/')}#WebSite`
        } as WithContext<WebSite>
      ]
    } as WithContext<WebPage>,
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      '@id': `https://portfolio.rottigni.tech${localePath('/')}#WebSite`,
      url: `https://portfolio.rottigni.tech${localePath('/')}`,
      name: 'Portfolio rottigni',
      description: "Lorenzo Rottigni's personal web portfolio",
      potentialAction: {
        '@type': 'SearchAction',
        target: `https://portfolio.rottigni.tech${localePath('/')}?search={search_term_string}`,
        'query-input': 'required name=search_term_string'
      },
      publisher: {
        '@type': 'Person',
        '@id': `https://portfolio.rottigni.tech${localePath('/')}#Person`,
        name: 'Lorenzo Rottigni',
        email: 'lorenzo@rottigni.net'
      } as WithContext<Person>
    } as WithContext<WebSite>,
    rottigniTechOrganization,
    lorenzoRottigniOrganization
  ])
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSDVideo = (media: Media & any) => {
  const localePath = useLocalePath()
  return useJsonld(() => ({
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    '@id': `https://portfolio.rottigni.tech${localePath(`/media/${media?.slug}`)}`,
    name: media?.name,
    mentions: [
      /**
       * media?.skills?.map(skill => ({
      '@type': 'Skill',
      '@id': `https://portfolio.rottigni.tech/skills/${skill?.skill?.slug}`,
      'name': skill?.skill?.name,
    }))
       */
      {
        '@type': 'SoftwareSourceCode',
        '@id': `https://portfolio.rottigni.tech${localePath(`/git/${media?.repo?.slug}`)}`,
        name: media?.repo?.name
      }
    ]
  }))
}

export const useSDRepository = (
  repo: Repo & {
    media: Media | null
    skills: (SkillsOnRepos & {
      skill: Skill
    })[]
    asset: Asset | null
    topics: TopicsOnRepos[]
  }
) => {
  return useJsonld(() => [
    {
      '@context': 'https://schema.org',
      '@type': 'SoftwareSourceCode',
      '@id': `https://portfolio.rottigni.tech/git/${repo.slug}#SoftwareSourceCode`,
      name: repo.name || '',
      description: repo.description || '',
      codeRepository: repo.publicUrl || '',
      programmingLanguage: repo.language ? [repo.language] : undefined,
      author: {
        '@type': 'Person',
        '@id': 'https://portfolio.rottigni.tech/#Person',
        name: 'Lorenzo Rottigni',
        email: 'lorenzo@rottigni.net'
      },
      dateCreated: '2022-01-01',
      dateModified: '2022-02-01',
      keywords: repo.topicRaw
        ? [
            ...repo.topicRaw.split(';'),
            ...repo.skills.map(
              (skill: { skill: Skill }) => `https://portfolio.rottigni.tech/skills/${skill?.skill?.slug}`
            )
          ]
        : repo.skills?.map((skill: { skill: Skill }) => `https://portfolio.rottigni.tech/skills/${skill?.skill?.slug}`),
      genre: 'Open source software',
      publisher: {
        '@type': 'Organization',
        '@id': 'https://portfolio.rottigni.tech/#Organization',
        name: 'LR Labs',
        url: 'https://portfolio.rottigni.tech/'
      },
      language: ['English'],
      thumbnailUrl: repo?.asset?.thumb || '',
      isPartOf: {
        '@type': 'CreativeWorkSeries',
        name: "Lorenzo Rottigni's Repositories series",
        url: 'https://portfolio.rottigni.tech/git/'
      },
      mainEntityOfPage: `https://portfolio.rottigni.tech/git/${repo.slug}`
    },
    {
      '@context': 'https://schema.org',
      '@type': 'SoftwareApplication',
      '@id': `https://portfolio.rottigni.tech/git/${repo.slug}#SoftwareApplication`,
      name: repo?.name || undefined,
      description: repo?.description || undefined,
      operatingSystem: 'Any',
      applicationCategory: 'DeveloperApplication',
      softwareVersion: '1.0.0',
      author: {
        '@type': 'Person',
        '@id': 'https://portfolio.rottigni.tech/#Person',
        name: 'Lorenzo Rottigni',
        email: 'lorenzo@rottigni.net'
      }
    },
    {
      '@context': 'https://schema.org',
      '@type': 'WebApplication',
      name: repo?.name || undefined,
      description: repo?.description || undefined,
      applicationCategory: 'DeveloperApplication',
      operatingSystem: 'Web-based',
      softwareVersion: '1.0.0',
      author: {
        '@type': 'Person',
        '@id': 'https://portfolio.rottigni.tech/#Person',
        name: 'Lorenzo Rottigni',
        email: 'lorenzo@rottigni.net'
      },
      url: `https://portfolio.rottigni.tech/git/${repo.slug}`,
      inLanguage: 'en'
    }
  ])
}

export const useSDSkill = (
  skill: Skill & {
    repos: (SkillsOnRepos & { repo: Repo })[]
  }
) => {
  const localePath = useLocalePath()
  return useJsonld({
    '@context': 'https://schema.org',
    '@type': 'DefinedTerm',
    '@id': `${localePath(`/skills/${skill.slug}`)}#Skill`,
    description: skill?.description || undefined,
    inDefinedTermSet: {
      '@type': 'DefinedTermSet',
      name: skill?.name || undefined,
      url: `https://portfolio.rottigni.tech${localePath(`/skills/${skill?.slug}`)}`,
      publisher: {
        '@type': 'Person',
        '@id': 'https://portfolio.rottigni.tech/#Person',
        name: 'Lorenzo Rottigni'
      },
      datePublished: '2023-09'
    }
  })
}
